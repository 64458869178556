<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div id="print" class="card-body" v-if="dailyJournal">
                            <div class="d-flex">
                                <div style="flex: 1">
                                    <img src="../../assets/img/bc-horizontal-new.png" height="50" alt="navbar brand" class="navbar-brand">
                                </div>
                                <div class="d-flex flex-column text-center">
                                    <h3 class="fw-bold mb-0">DAILY JOURNAL</h3>
                                    <h4 class="fw-bold mb-0">Academic Year {{ dailyJournal?.date.split('-')[0] ?? '' }}</h4>
                                </div>
                                <div style="flex: 1" class="text-right">
                                    <img src="../../assets/img/daily.png" height="50">
                                </div>
                            </div>
                            <table class="table-bordered table mt-2">
                                <tbody>
                                <tr>
                                    <td>Day :</td>
                                    <td>{{ dailyJournal.day }}</td>
                                    <td>PIC of The Day :</td>
                                    <td>{{ dailyJournal.pic_name }}</td>
                                </tr>
                                <tr>
                                    <td>Date :</td>
                                    <td>{{ toIndoDateName(dailyJournal.date) }}</td>
                                    <td>Lesson Hours :</td>
                                    <td>{{ dailyJournal.lesson_hour }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="table-bordered table mt-2">
                                <tbody>
                                <tr>
                                    <td rowspan="6" style="vertical-align: top !important; width: 36px">I.</td>
                                    <td colspan="3">Teachers Attendance</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-center">Total</td>
                                    <td class="text-center">Note</td>
                                </tr>
                                <tr>
                                    <td>a. Present</td>
                                    <td>{{ dailyJournal.teacher_present }}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>b. Absent</td>
                                    <td>{{ dailyJournal.teacher_absent }}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="text-center">Substitute Teacher</td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="padding: 0 !important;">
                                        <table class="table table-bordered mb-0 border-none">
                                            <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>Subject</td>
                                                <td>Grade</td>
                                                <td>Time</td>
                                                <td>Replaced By</td>
                                            </tr>
                                            <tr v-for="v in dailyJournal.teacher">
                                                <td>{{ v.name }}</td>
                                                <td>{{ v.subject_name }}</td>
                                                <td>{{ v.grade_name }}</td>
                                                <td>{{ v.time }}</td>
                                                <td>{{ v.substitute_name }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="table-bordered table mt-2">
                                <tbody>
                                <tr>
                                    <td rowspan="6" style="vertical-align: top !important; width: 36px">II.</td>
                                    <td colspan="4">Santri Attendance</td>
                                </tr>
                                <tr>
                                    <td colspan="4">a. Absence</td>
                                </tr>
                                <tr>
                                    <td colspan="4" style="padding: 0 !important;">
                                        <table class="table table-bordered mb-0 border-none">
                                            <tbody>
                                            <tr>
                                                <td>Grade</td>
                                                <td>Name</td>
                                                <td>Note</td>
                                            </tr>
                                            <tr v-for="v in dailyJournal.student.absent">
                                                <td>{{ v.grade_name }}</td>
                                                <td>{{ v.name }}</td>
                                                <td>{{ attendanceStatus(v.status) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-right">Total</td>
                                                <td>{{ dailyJournal.student_absent ?? dailyJournal.student.absent.length }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4"> </td>
                                </tr>
                                <tr>
                                    <td colspan="2">b. Come late </td>
                                    <td colspan="2">b. Leave early </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="padding: 0 !important;">
                                        <table class="table table-bordered mb-0 border-none">
                                            <tbody>
                                            <tr>
                                                <td class="text-center">Name</td>
                                                <td class="text-center">Grade</td>
                                                <td class="text-center">Note</td>
                                            </tr>
                                            <tr v-for="v in dailyJournal.student.come_late">
                                                <td>{{ v.name }}</td>
                                                <td>{{ v.grade_name }}</td>
                                                <td><pre>{{ v.note }}</pre></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td colspan="2" style="padding: 0 !important;">
                                        <table class="table table-bordered mb-0 border-none">
                                            <tbody>
                                            <tr>
                                                <td class="text-center">Name</td>
                                                <td class="text-center">Grade</td>
                                                <td class="text-center">Note</td>
                                            </tr>
                                            <tr v-for="v in dailyJournal.student.leave_early">
                                                <td>{{ v.name }}</td>
                                                <td>{{ v.grade_name }}</td>
                                                <td><pre>{{ v.note }}</pre></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="table-responsive">
								<table class="table-bordered table mt-2">
									<tbody>
                                    <tr>
                                        <td rowspan="2" style="width: 36px">III.</td>
                                        <td>Cases and Solution</td>
                                    </tr>
                                    <tr>
                                        <td><pre>{{ dailyJournal.cases }}</pre></td>
                                    </tr>
                                    </tbody>
								</table>
							</div>
                            <table class="table-bordered table mt-2">
                                <tbody>
                                <tr>
                                    <td rowspan="2" style="width: 36px">IV.</td>
                                    <td>Evaluation</td>
                                </tr>
                                <tr>
                                    <td><pre>{{ dailyJournal.evaluation }}</pre></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-danger mr-2" @click="back">Kembali</button>
                            <button class="btn btn-success" @click="download">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Api from "@/utils/Api";
import DailyJournalRequest, {Teacher} from "@/utils/interface/DailyJournal";
import {useRoute, useRouter} from "vue-router";
import {attendanceStatus, toIndoDateName} from "@/utils/Helpers";
import {error, loading} from "@/utils/Toast";

export default defineComponent({
    name: "DetailDailyJournal",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const id = route.params.id
        const dailyJournal = ref<DailyJournalRequest|null>(null)
        const refPic = ref<{}[]>([])

        onMounted(async () => {
            const t = loading('Mendapatkan data')
            const r = await Api.get(`daily-journal/${id}`)
            const r2 = await Api.get('master/guru')
            t.remove()

            if (r.success) {
                dailyJournal.value = r.data as DailyJournalRequest
                refPic.value = r2.data
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        return {
            dailyJournal,
            toIndoDateName,
            refPic,
            attendanceStatus,
            back: () => router.back(),
            download
        }
    }
})

const download = () => {
    const prtHtml = document.getElementById('print')!.innerHTML;
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }
    stylesHtml += '<style>.table .table{background-color: transparent !important;}*{font-family:"Times New Roman",serif !important;color:#000!important}.table td,.table th{height:auto!important;padding:6px!important}.border-none{border-collapse:collapse;border:none}.border-none td{border:1px solid #000}.border-none tr:first-child td{border-top:none}.border-none tr:last-child td{border-bottom:none}.border-none tr td:first-child{border-left:none}.border-none tr td:last-child{border-right:none}</style>'

    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0') as WindowProxy;
    WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body style="padding: 10px; font-size: 150% !important;">
            ${prtHtml}
          </body>
        </html>`
    );

    WinPrint.document.close();
    WinPrint.focus();
    setTimeout(() => WinPrint.print(), 100)
}
</script>

<style scoped>
#print * {
    font-family: 'Times New Roman', serif !important;
    color: black;
}

.table td, .table th {
    height: auto !important;
    padding: 6px !important;
}

.border-none {
    border-collapse: collapse;
    border: none;
}

.border-none td {
    border: 1px solid black;
}

.border-none tr:first-child td {
    border-top: none;
}

.border-none tr:last-child td {
    border-bottom: none;
}

.border-none tr td:first-child {
    border-left: none;
}

.border-none tr td:last-child {
    border-right: none;
}

pre {
    font-size: 100%;
}
</style>
