import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner mt--5" }
const _hoisted_3 = { class: "row mt--2" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-12 col-md-12 col-lg-4" }
const _hoisted_7 = {
  class: "card",
  style: {"height":"892px"}
}
const _hoisted_8 = {
  class: "card-body d-flex flex-column",
  style: {"height":"100%"}
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "fw-bold mb-0 mt-2" }
const _hoisted_11 = { class: "fw-bold mb-0" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = {
  class: "fw-bold mb-0",
  style: {"font-size":"42px"}
}
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "card" }
const _hoisted_19 = { class: "card-body" }
const _hoisted_20 = {
  class: "fw-bold mb-0",
  style: {"font-size":"42px"}
}
const _hoisted_21 = { class: "col-sm-12 col-md-12 col-lg-8" }
const _hoisted_22 = { class: "card" }
const _hoisted_23 = { class: "card-header" }
const _hoisted_24 = { class: "card-head-row" }
const _hoisted_25 = { class: "card-tools" }
const _hoisted_26 = { class: "card-body" }
const _hoisted_27 = { class: "col-sm-12 col-md-12" }
const _hoisted_28 = { class: "card" }
const _hoisted_29 = { class: "card-header" }
const _hoisted_30 = { class: "card-head-row" }
const _hoisted_31 = { class: "card-tools" }
const _hoisted_32 = { class: "card-body" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["href"]
const _hoisted_35 = {
  class: "d-flex align-items-center",
  style: {"width":"100%"}
}
const _hoisted_36 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardAttendance = _resolveComponent("DashboardAttendance")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_PaginatedTable = _resolveComponent("PaginatedTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"panel-header bg-primary-gradient\"><div class=\"page-inner py-5\"><div class=\"d-flex align-items-left align-items-md-center flex-column flex-md-row\"><div><h2 class=\"text-white pb-2 fw-bold\">BC Smart School</h2><h5 class=\"text-white op-7 mb-2\">Bumi Cendekia School Management Information System</h5></div><div class=\"ml-md-auto py-2 py-md-0\"></div></div></div></div>", 1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("img", {
                      src: _ctx.getPhoto(_ctx.user),
                      alt: "...",
                      class: "avatar-img rounded-circle mt-3",
                      style: {"width":"70px","height":"70px"}
                    }, null, 8, _hoisted_9),
                    _createElementVNode("h1", _hoisted_10, " Hi, " + _toDisplayString(_ctx.user.name), 1),
                    _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.msg), 1),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bold" }, "Jumlah Guru", -1)),
                            _createElementVNode("h1", _hoisted_16, _toDisplayString(_ctx.guruCount), 1)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fw-bold" }, "Jumlah Siswa", -1)),
                            _createElementVNode("h1", _hoisted_20, _toDisplayString(_ctx.siswaCount), 1)
                          ])
                        ])
                      ])
                    ]),
                    _createVNode(_component_DashboardAttendance)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-title" }, "Academic Calendar", -1)),
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_router_link, { to: "/academic/calendar" }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createElementVNode("button", { class: "b-time btn btn-success btn-round btn-sm mr-2" }, [
                              _createElementVNode("i", { class: "fa fa-pencil" })
                            ], -1)
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_Calendar, {
                      nearest: _ctx.nearest,
                      agendas: _ctx.agendas,
                      "on-agenda-click": _ctx.onAgendaClick
                    }, null, 8, ["nearest", "agendas", "on-agenda-click"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-title" }, "Announcement", -1)),
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_router_link, { to: "/announcement" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("button", { class: "b-time btn btn-success btn-round btn-sm mr-2" }, [
                          _createElementVNode("i", { class: "fa fa-plus" })
                        ], -1)
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_PaginatedTable, { data: _ctx.announcements }, {
                  thead: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("th", null, "No", -1),
                    _createElementVNode("th", null, "Created At", -1),
                    _createElementVNode("th", null, "Title", -1),
                    _createElementVNode("th", null, "Message", -1),
                    _createElementVNode("th", null, "Message Detail", -1),
                    _createElementVNode("th", null, "Attachment", -1)
                  ])),
                  tbody: _withCtx(({row, index}) => [
                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.toIndoDateNameTime(row.data.created_at)), 1),
                    _createElementVNode("td", null, _toDisplayString(row.data.title), 1),
                    _createElementVNode("td", null, _toDisplayString(row.data.message), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        class: "btn btn-success btn-sm",
                        onClick: ($event: any) => (_ctx.detail(row.data.action_detail))
                      }, " Show Detail ", 8, _hoisted_33)
                    ]),
                    _createElementVNode("td", null, [
                      (row.data.pdf)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            target: "_blank",
                            href: row.data.pdf,
                            class: "btn btn-success btn-sm"
                          }, _cache[10] || (_cache[10] = [
                            _createElementVNode("i", { class: "fa fa-download" }, null, -1)
                          ]), 8, _hoisted_34))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Modal, {
      show: _ctx.showModal,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_35, [
          _cache[12] || (_cache[12] = _createElementVNode("h4", {
            class: "mb-0",
            style: {"flex":"1"}
          }, "Detail Agenda", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(`my-cv-item-${_ctx.dataModal?.customData?.color}`),
            style: {"width":"15px","height":"15px","border-radius":"20px"}
          }, null, 2)
        ])
      ]),
      body: _withCtx(() => [
        _cache[13] || (_cache[13] = _createElementVNode("b", null, "Agenda", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.dataModal?.title), 1),
        _cache[14] || (_cache[14] = _createElementVNode("b", null, "Date", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.toIndoDateName(_ctx.dataModal?.start_date)) + " - " + _toDisplayString(_ctx.toIndoDateName(_ctx.dataModal?.end_date)), 1)
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_Modal, {
      show: _ctx.showModalAnnouncement,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalAnnouncement = false)),
      title: "Message Detail"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", { innerHTML: _ctx.modalAnnouncementData }, null, 8, _hoisted_36)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}