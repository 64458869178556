import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "page-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 col-lg-10 col-xl-8 col-xxl-6" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "img-ctr" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-md-12" }
const _hoisted_15 = ["src"]

import {isMobile, getPhoto, file2Base64} from "@/utils/Helpers";
import {onMounted, ref} from "vue";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSetting',
  setup(__props) {


const sliders = ref([])
const photoUrl = ref<string>('')
const photoB64 = ref<string|null>(null)
const fileInput = ref<HTMLInputElement | null>(null)

const triggerFileInput = () => {
    fileInput.value?.click()
}

const previewPhoto = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        if (file.size > 1000000) {
            error('Maksimal ukuran gambar 1 MB')
            return
        }
        const reader = new FileReader();
        reader.onload = () => {
            photoUrl.value = reader.result as string;
        };
        reader.readAsDataURL(file);
        photoB64.value = await file2Base64(file)
    }
}

onMounted(async () => {
    const t = loading('Mendapatkan data')
    const r = await Api.get(`slider`)
    if (r.success) {
        sliders.value = r.data
    }
    t.remove()
})

const add_slider = async () => {
    const t = loading('Menambahkan slider...')
    const r = await Api.post(`slider`, {photo_file: photoB64.value})
    if (r.success) {
        sliders.value = r.data
        photoUrl.value = ''
        photoB64.value = null
    }
    else {
        error(r.message ?? 'gagal')
    }
    t.remove()
}

const delete_slider = async (id: any) => {
    if (!confirm('Yakin hapus slider ini?')) {
        return
    }

    const t = loading('Menghapus slider...')
    const r = await Api.delete(`slider/delete/${id}`)
    if (r.success) {
        sliders.value = r.data
    }
    else {
        error(r.message ?? 'gagal')
    }
    t.remove()
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("div", { class: "card-head-row" }, [
                _createElementVNode("div", { class: "card-title fw-bold" }, "Slider Image")
              ])
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["card-header", {'px-0': _unref(isMobile)()}])
            }, [
              _createElementVNode("div", _hoisted_6, [
                (!sliders.value.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[0] || (_cache[0] = [
                      _createElementVNode("i", null, "Tidak ada slider", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sliders.value, (v) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: _unref(getPhoto)(v.value),
                        alt: "..."
                      }, null, 8, _hoisted_10)
                    ]),
                    _createElementVNode("button", {
                      class: "btn btn-sm btn-danger",
                      onClick: () => delete_slider(v.id)
                    }, "Hapus", 8, _hoisted_11)
                  ]))
                }), 256))
              ])
            ], 2),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "fw-bold mb-0" }, "Tambah Slider Image")
            ], -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("img", {
                    src: photoUrl.value || require('@/assets/img/phhr.png'),
                    alt: "...",
                    style: {"width":"200px"},
                    class: "rounded",
                    id: "photo-display"
                  }, null, 8, _hoisted_15),
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/*",
                    name: "photo_file",
                    onChange: previewPhoto,
                    ref_key: "fileInput",
                    ref: fileInput,
                    style: {"display":"none"}
                  }, null, 544),
                  _createElementVNode("button", {
                    type: "button",
                    onClick: triggerFileInput,
                    class: "btn btn-round btn-outline-success ml-2"
                  }, "Pilih Gambar")
                ])
              ]),
              _createElementVNode("div", { class: "row" }, [
                _createElementVNode("div", { class: "col-md-4" }, [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-success",
                    style: {"margin-top":"36px"},
                    onClick: add_slider
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("i", { class: "fas fa-save" }, null, -1),
                    _createTextVNode(" Simpan ")
                  ]))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})