<script setup lang="ts">

import {isMobile, getPhoto, file2Base64} from "@/utils/Helpers";
import {onMounted, ref} from "vue";
import {error, loading} from "@/utils/Toast";
import Api from "@/utils/Api";

const sliders = ref([])
const photoUrl = ref<string>('')
const photoB64 = ref<string|null>(null)
const fileInput = ref<HTMLInputElement | null>(null)

const triggerFileInput = () => {
    fileInput.value?.click()
}

const previewPhoto = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        const file = target.files[0];
        if (file.size > 1000000) {
            error('Maksimal ukuran gambar 1 MB')
            return
        }
        const reader = new FileReader();
        reader.onload = () => {
            photoUrl.value = reader.result as string;
        };
        reader.readAsDataURL(file);
        photoB64.value = await file2Base64(file)
    }
}

onMounted(async () => {
    const t = loading('Mendapatkan data')
    const r = await Api.get(`slider`)
    if (r.success) {
        sliders.value = r.data
    }
    t.remove()
})

const add_slider = async () => {
    const t = loading('Menambahkan slider...')
    const r = await Api.post(`slider`, {photo_file: photoB64.value})
    if (r.success) {
        sliders.value = r.data
        photoUrl.value = ''
        photoB64.value = null
    }
    else {
        error(r.message ?? 'gagal')
    }
    t.remove()
}

const delete_slider = async (id: any) => {
    if (!confirm('Yakin hapus slider ini?')) {
        return
    }

    const t = loading('Menghapus slider...')
    const r = await Api.delete(`slider/delete/${id}`)
    if (r.success) {
        sliders.value = r.data
    }
    else {
        error(r.message ?? 'gagal')
    }
    t.remove()
}

</script>

<template>
    <div class="content">
        <div class="page-inner">
            <div class="row">
                <div class="col-md-12 col-lg-10 col-xl-8 col-xxl-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title fw-bold">Slider Image</div>
                            </div>
                        </div>
                        <div class="card-header" :class="{'px-0': isMobile()}">
                            <div class="row">
                                <div v-if="!sliders.length" class="col-md-12">
                                    <i>Tidak ada slider</i>
                                </div>
                                <div v-for="v in sliders" class="col-md-6">
                                    <div class="img-ctr">
                                        <img :src="getPhoto(v.value)" alt="...">
                                    </div>
                                    <button class="btn btn-sm btn-danger" @click="() => delete_slider(v.id)">Hapus</button>
                                </div>
                            </div>
                        </div>

                        <div class="card-header">
                            <h4 class="fw-bold mb-0">Tambah Slider Image</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <img :src="photoUrl || require('@/assets/img/phhr.png')" alt="..." style="width: 200px" class="rounded" id="photo-display">
                                    <input type="file" accept="image/*" name="photo_file" @change="previewPhoto" ref="fileInput" style="display: none">
                                    <button type="button" @click="triggerFileInput" class="btn btn-round btn-outline-success ml-2">Pilih Gambar</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-success" style="margin-top: 36px" @click="add_slider">
                                        <i class="fas fa-save"></i> Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.img-ctr {
    margin: 12px 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    padding-top: 42%;
}
.img-ctr img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
</style>