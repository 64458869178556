import {createRouter, createWebHistory} from 'vue-router'
import dailyJournalRoutes from './daily-journal'
import masterDataRoutes from './master-data'
import classroomJournalRoutes from './classroom-journal'
import assignmentRoutes from './assignment'
import academicRoutes from './academic'
import otherRoutes from './other'
import Login from '@/components/login/Login.vue'
import Dashboard from '@/components/dashboard/Dashboard.vue'

import ortuRoutes from './ortu'
import studentRoutes from './student'
import dokterRoutes from './dokter'
import AppSetting from "@/components/other/AppSetting.vue";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },
    ...masterDataRoutes,
    ...dailyJournalRoutes,
    ...classroomJournalRoutes,
    ...assignmentRoutes,
    ...academicRoutes,
    ...otherRoutes,
    ...ortuRoutes,
    ...studentRoutes,
    ...dokterRoutes,
    {
        path: '/app-setting',
        name: 'app-setting',
        component: AppSetting,
        meta: {
            title: 'App Setting'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
