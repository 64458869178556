<template>
    <div class="content">
        <div class="panel-header bg-primary-gradient">
            <div class="page-inner py-5">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                    <div>
                        <h2 class="text-white pb-2 fw-bold">BC Smart School</h2>
                        <h5 class="text-white op-7 mb-2">Bumi Cendekia School Management Information System</h5>
                    </div>
                    <div class="ml-md-auto py-2 py-md-0">
                    </div>
                </div>
            </div>
        </div>
        <div class="page-inner mt--5">
            <div class="row mt--2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="card" style="height: 892px">
                                <div class="card-body d-flex flex-column" style="height: 100%">
                                    <img :src="getPhoto(user)" alt="..." class="avatar-img rounded-circle mt-3" style="width: 70px; height: 70px">
                                    <h1 class="fw-bold mb-0 mt-2">
                                        Hi, {{ user.name }}
                                    </h1>
                                    <h2 class="fw-bold mb-0">
                                        {{ msg }}
                                    </h2>
                                    <div style="flex: 1"></div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card">
                                                <div class="card-body">
                                                    <span class="fw-bold">Jumlah Guru</span>
                                                    <h1 class="fw-bold mb-0" style="font-size: 42px">{{ guruCount }}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card">
                                                <div class="card-body">
                                                    <span class="fw-bold">Jumlah Siswa</span>
                                                    <h1 class="fw-bold mb-0" style="font-size: 42px">{{ siswaCount }}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DashboardAttendance/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8">
                            <div class="card">
                                <div class="card-header">
                                    <div class="card-head-row">
                                        <div class="card-title">Academic Calendar</div>
                                        <div class="card-tools">
                                            <router-link to="/academic/calendar">
                                                <button class="b-time btn btn-success btn-round btn-sm mr-2">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <Calendar :nearest="nearest" :agendas="agendas" :on-agenda-click="onAgendaClick"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-head-row">
                                <div class="card-title">Announcement</div>
                                <div class="card-tools">
                                    <router-link to="/announcement">
                                        <button class="b-time btn btn-success btn-round btn-sm mr-2">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
							<PaginatedTable :data="announcements">
								<template #thead>
									<th>No</th>
									<th>Created At</th>
									<th>Title</th>
									<th>Message</th>
									<th>Message Detail</th>
									<th>Attachment</th>
								</template>
								<template #tbody="{row, index}">
									<td>{{ index + 1 }}</td>
									<td>{{ toIndoDateNameTime(row.data.created_at) }}</td>
									<td>{{ row.data.title }}</td>
									<td>{{ row.data.message }}</td>
									<td>
										<button class="btn btn-success btn-sm" @click="detail(row.data.action_detail)">
											Show Detail
										</button>
									</td>
									<td>
										<a v-if="row.data.pdf" target="_blank" :href="row.data.pdf" class="btn btn-success btn-sm">
											<i class="fa fa-download"></i>
										</a>
									</td>
								</template>
							</PaginatedTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :show="showModal" @close="showModal = false">
        <template #header>
            <div class="d-flex align-items-center" style="width: 100%">
                <h4 class="mb-0" style="flex: 1">Detail Agenda</h4>
                <div :class="`my-cv-item-${dataModal?.customData?.color}`" style="width: 15px; height: 15px; border-radius: 20px"></div>
            </div>
        </template>
        <template #body>
            <b>Agenda</b>
            <p>{{ dataModal?.title }}</p>
            <b>Date</b>
            <p>{{ toIndoDateName(dataModal?.start_date) }} - {{ toIndoDateName(dataModal?.end_date) }}</p>
        </template>
    </Modal>
    <Modal
        :show="showModalAnnouncement"
        @close="showModalAnnouncement = false"
        title="Message Detail"
    >
        <template #body>
            <div v-html="modalAnnouncementData"></div>
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {AgendaResponse, agendaResponseToI, IAgenda} from "@/utils/interface/Agenda";
import {toast} from "vue3-toastify";
import Api from "@/utils/Api";
import Calendar from "@/components/partials/Calendar.vue";
import {IOption} from "@/utils/interface/Assignment";
import {Siswa, User} from "@/utils/Models";
import Session from "@/utils/Session";
import {getPhoto, toIndoDateName, toIndoDateNameTime} from "@/utils/Helpers";
import {IAnnouncement} from "@/utils/interface/Announcement";
import {error, loading} from "@/utils/Toast";
import Modal from "@/components/partials/Modal.vue";
import DashboardAttendance from "@/components/dashboard/DashboardAttendance.vue";
import PaginatedTable from "@/components/common/PaginatedTable.vue";

export default defineComponent({
    name: "Dashboard",
    components: {
        DashboardAttendance,
        Modal,
        Calendar,
		PaginatedTable
    },
    setup() {
        const announcements = ref<IAnnouncement[]>([])
        const showModal = ref(false)
        const dataModal = ref<IAgenda|null>(null)
        const showModalAnnouncement = ref(false)
        const modalAnnouncementData = ref('')
        const agendas = ref<IAgenda[]>()
        const nearest = ref<IAgenda[]>()
        const guruCount = ref<number>(0)
        const siswaCount = ref<number>(0)
        const user = Session.getCurrentUser()!
        const date = new Date()
        const msg = date.getHours() < 12 ? 'Semangat untuk pagi hari ini!' :
            date.getHours() < 15 ? 'Tetap semangat meski hari sudah siang :)' :
            date.getHours() < 18 ? 'Semangat sore!!!' : 'Tetap semangat mengajar di malam hari!'

        const get_announcement = async () => {
            const r = await Api.get('announcement')
            if (r.success) {
                announcements.value = r.data as IAnnouncement[]
            }
        }

        onMounted(async () => {
            get_announcement()

            const t = loading('Mendapatkan data')
            const [r, r3, gc, sc] = await Promise.all([
                Api.get('agenda'),
                Api.get('agenda/nearest'),
                Api.get('master/guru_count'),
                Api.get('master/siswa_count')
            ])

            t.remove()
            if (r.success) {
                agendas.value = (r.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
            if (gc.success) {
                guruCount.value = gc.data
            }
            else {
                error(gc.message ?? 'gagal')
            }
            if (sc.success) {
                siswaCount.value = sc.data
            }
            else {
                error(sc.message ?? 'gagal')
            }
            if (r3.success) {
                nearest.value = (r3.data as AgendaResponse[]).map(v => agendaResponseToI(v))
            }
            else {
                error(r.message ?? 'gagal')
            }
        })

        const onAgendaClick = (v: IAgenda) => {
            dataModal.value = v
            showModal.value = true
        }

        const detail = (detail: string) => {
            showModalAnnouncement.value = true
            modalAnnouncementData.value = detail
        }

        return {
            agendas, guruCount, siswaCount, user, msg, getPhoto, announcements, toIndoDateNameTime,
            onAgendaClick, dataModal, showModal, toIndoDateName, nearest, detail, showModalAnnouncement, modalAnnouncementData
        }
    }
})
</script>

<style scoped>

</style>
